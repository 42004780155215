export const shiftFeeParcelVisuals = [
  {
    name: "Demo",
    markdown: `![Shift fee parcel GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const shiftFeeParcel = {
  order: 22,
  name: "Shift fee parcels left or right",
  subcategory: "Properties",
  markdown: `# Shift fee parcels left or right

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}**Reputable users**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  When a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %} includes multiple {% inlineRouterLink articleId="add-a-fee-parcel" %}fee parcels{% /inlineRouterLink %}, you may want to display them in a certain horizontal order. To do this, you can move a parcel left or right one place at a time subject to certain restrictions noted below.

  ## What to expect

  Due to the complexity of certain diagram layouts, there are cases where it is not currently possible to shift a fee parcel:

  - When a {% inlineRouterLink articleId="add-a-reciprocal-easement" %}reciprocal easement{% /inlineRouterLink %} is present
  - When a {% inlineRouterLink articleId="add-a-land-covering" %}land covering spans multiple fee parcels{% /inlineRouterLink %}

  ## Steps

  {% tablessContainer %}
  1. Visit a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.
  2. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" %}**Select**{% /inlineRouterLink %} a {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Fee parcels" %}fee parcel{% /inlineRouterLink %}.
  3. In the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %}, click {% inlinePropertyDiagramIcon name="shiftFeeSimpleLeft" /%} or {% inlinePropertyDiagramIcon name="shiftFeeSimpleRight" /%}.
  4. Click {% inlinePropertyDiagramIcon name="saveEditAction" /%} to save. The parcel (and everything on it) moves one place in the selected direction.
  {% /tablessContainer %}

  ## Visuals

  {% tabbedContainer tabs=$shiftFeeParcelVisuals /%}
  `,
};
